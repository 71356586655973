import React, {Component, RefObject} from "react";
import EventManager from "../../services/events/event-manager";
import TYPES from "../../services/ioc/TYPES";
import {resolve} from "inversify-react";
import NewTodoItemEvent from "../../models/events/new-todo-item.event";
import {ENTER_KEY} from "../../models/constants/keyboard";
import BrowserIdentifier from "../../services/support/browser-identifier";


class TodoListHeader extends Component {

    private readonly newFieldRef: RefObject<any>;

    // @ts-ignore
    @resolve(TYPES.TodoEventManager) private _todoEventManager: EventManager;

    // @ts-ignore
    @resolve(TYPES.BrowserIdentifier) private _browserIdentifier: BrowserIdentifier;

    constructor(props: any, context: any) {
        super(props, context);

        this.newFieldRef = React.createRef();
    }

    render() {

        const placeholder = this.getPlaceholderText();

        return (
            <header className="header">
                <h1>todos</h1>
                <input
                    ref={this.newFieldRef}
                    className="new-todo"
                    placeholder={placeholder}
                    onKeyDown={e => this.handleNewTodoKeyDown(e)}
                    autoFocus={true}
                />
            </header>
        );
    }

    private handleNewTodoKeyDown(event: React.KeyboardEvent) {

        if (event.keyCode !== ENTER_KEY) {
            return;
        }

        event.preventDefault();

        const node = this.newFieldRef.current;

        const nodeValue = node.value.trim();

        if (!nodeValue) {
            //error-spec: allow create empty items
            //return;
        }

        // error-spec: if title length more than 15 chars trim the last char
        const title = node.value.length < 15 ? node.value : node.value.substring(0, node.value.length - 1);

        this._todoEventManager.dispatch(new NewTodoItemEvent(title));

        node.value = '';
    }

    private getPlaceholderText(): string {

        const doneWord = this._browserIdentifier.isEdge() || this._browserIdentifier.isSafari() ? 'do' : 'done';

        return `What needs to be ${doneWord}?`;
    }
}

export default TodoListHeader;